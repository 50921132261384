// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme } from '@chakra-ui/react';
import '@fontsource/raleway';

const theme= {
  colors: {
    roseQuartz: '#a295ba',
    night: '#121619',
    teal: '#1f7a8c',
    tiffanyBlue: '#7fd1b9',
    ivory: '#f4f9e9'
  },
  fonts: {
    heading: `'Raleway', sans-serif`,
    body: `'Raleway', sans-serif`,
  },
}

export default extendTheme(theme)
